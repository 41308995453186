import { GatsbyImage } from 'gatsby-plugin-image'
import { groupBy, kebabCase } from 'lodash'
import React, { useCallback } from 'react'
import Link from '../components/link'
import ConditionallyRender from './conditionallyRender'

const LineItems = ({ projects, activateItem, firstGroup, link, secondClassName, target }) => {
	const getMonth = useCallback(date => {
		const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
		let [_, month] = date?.trim()?.toLowerCase()?.split('/')
		if (month[0] === '0') {
			month = month.replace('0', '')
		}

		const computedMonth = months[month - 1]?.toUpperCase()

		return computedMonth
	}, [])

	const NewsContent = ({ item }) => {
		const isBlog = item?.node?.id?.includes('DatoCmsBlog')
		const showLink = Boolean(item?.node?.link || item?.node?.slug)

		if (isBlog) return null
		return (
			<div className='bb1 pt15 pb15 flex uppercase inner'>
				<div className='flex w-100-120 m-100 '>
					<p className='m0 w-30 m-w-40  m-m0 m-mr20 text-initial news uppercase'>{getMonth(item?.node?.title)}</p>
					<div className='m0 w-20 m-w-60 m-100 '>
						<p className='m0 m-m0 text-initial news'>{isBlog ? 'BLOG' : item?.node?.location}</p>
						<div className='m-show'>
							<p className='op-50 mb20 text-initial'>{item?.node?.info}</p>
						</div>
						<ConditionallyRender when={showLink}>
							<div className='read-more ml10 m-show m-100 mt20 m-ml0 news  '>
								<p className='m0 button small flex news' style={{ width: '120px' }}>
									Read More
								</p>
							</div>
						</ConditionallyRender>
					</div>
					<p className={'m0 w-40 m-hide ' + secondClassName}>{item.node.info}</p>
				</div>

				<div className={'read-more ml10 m-hide active news  ' + (!showLink ? 'op-0' : '')}>
					<p className={'m0 button small flex '} style={{ width: '120px' }}>
						Read More
					</p>
				</div>
			</div>
		)
	}

	return sortByMonth(projects)
		.reverse()
		.map((item, index) => {
			if (item.node.link) {
				return (
					<Link target={target} to={item?.node?.link ? item.node.link : `/projects/${kebabCase(item.node.title.toLowerCase())}`} data-item={item.node.id} key={index} className={'ordered--item w-100 link ' + (index === 0 && firstGroup ? 'active' : '')} onFocus={activateItem} onMouseOver={activateItem}>
						<NewsContent item={item} />
					</Link>
				)
			} else {
				return (
					<button target={target} data-item={item.node.id} key={index} className={'ordered--item w-100 link  ' + (index === 0 && firstGroup ? 'active' : '')} onMouseOver={activateItem} onFocus={activateItem}>
						<NewsContent item={item} />
					</button>
				)
			}
		})
}
function sortByMonth(items) {
	const sortedItems = items.sort((a, b) => {
		const [aDate, aMonth, aYear] = a?.node?.title.toLowerCase().trim().split('/')
		const [bDate, bMonth, bYear] = b?.node?.title.toLowerCase().trim().split('/')

		return new Date(aYear, aMonth, aDate) - new Date(bYear, bMonth, bDate)
	})

	return sortedItems
}

const OrderedList = ({ items, secondRowName, thirdRowName, secondClassName, target }) => {
	const activateItem = e => {
		document.querySelector('.ordered--item.active')?.classList.remove('active')
		var item = e.target
		item.classList.add('active')
		var number = item.getAttribute('data-item')
		document.querySelector('.item--image.active').classList.remove('active')
		document.querySelector('.item--image[data-image="' + number + '"]').classList.add('active')
	}

	const lineYears = Object.entries(groupBy(items, 'node.year'))
		.reverse()
		.map(([year, projects], index) => {
			return (
				<div className={index !== 0 ? 'mt40 mb40 m-mt100 m-mb100' : ''} key={index}>
					<div className='bb1 pb10'>{year}</div>
					<LineItems target={target} secondClassName={secondClassName} projects={projects} firstGroup={index === 0} activateItem={activateItem} />
				</div>
			)
		})

	const images = items.map((item, index) => {
		return (
			<div key={index} className={'bg-image item--image ' + (index === 0 ? 'active' : '')} data-image={item.node.id}>
				<GatsbyImage className='bg-image' image={item.node.featureImage?.gatsbyImageData} alt={item.node.title} />
			</div>
		)
	})

	return (
		<div className='ordered--list p40'>
			<div className='max-1400 ma flex'>
				<div className='image-column pt10 m-hide'>
					<div className='max-180 sticky--top'>
						<div className='ratio-2-1 bg-grey'>{images}</div>
					</div>
				</div>
				<div className='ordered-list w-100 pl40 m-p0'>{lineYears}</div>
			</div>
		</div>
	)
}

export default OrderedList
